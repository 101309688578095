export function gradientTripple(t, start, middle, end) {
  return t >= 0.5
    ? linear(middle, end, (t - 0.5) * 2)
    : linear(start, middle, t * 2);
}

export function gradient(t, start, end) {
  const a = linear(start, end, t);
  return a;
}

function linear(s, e, x) {
  let r = byteLinear(s[1] + s[2], e[1] + e[2], x);
  let g = byteLinear(s[3] + s[4], e[3] + e[4], x);
  let b = byteLinear(s[5] + s[6], e[5] + e[6], x);
  return "#" + r + g + b;
}

// a,b are hex values from 00 to FF; x is real number in range 0..1
function byteLinear(a, b, x) {
  let y = (("0x" + a) * (1 - x) + ("0x" + b) * x) | 0;
  return y.toString(16).padStart(2, "0"); // hex output
}

export const primaryColors = {
  dark: [
    "#D88972",
    "#E9A643",
    "#7BB695",
    "#A47CD8",
    "#689879",
    "#BFD08D",
    "#EC9999",
    "#CBB086",
    "#AA8FC1",
    "#4BAAC7",
  ],
  light: [
    "#FEAD94",
    "#FFC671",
    "#AFE7C8",
    "#CAA6F8",
    "#92BDA1",
    "#DFEFAF",
    "#FFBBBB",
    "#E8D4B4",
    "#C4AFD6",
    "#75D0EB",
  ],
};
export const secondaryColors = {
  dark: [
    "#DFC785",
    "#93A4DE",
    "#DAAFCD",
    "#C585B7",
    "#71C4E8",
    "#9BE795",
    "#7DAFDF",
    "#EBC46D",
    "#EEC4A2",
    "#97C9DA",
  ],
  light: [
    "#F1DFAD",
    "#BFCDFF",
    "#F4D9EC",
    "#E3A0D5",
    "#AAE5FF",
    "#C1FFBB",
    "#9ACEFF",
    "#F4D692",
    "#FCE2CD",
    "#C7E7F2",
  ],
};
